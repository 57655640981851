import React from "react";
import { useInit } from "./hooks/useInit";
import { Loading } from "../../components";
import { Redirect } from "react-router-dom";
import { routes } from "../../router/routes";
import { useLocation } from "react-router-dom";
import { useQueryParams } from "../../context/hooks";
import { useAuth } from "../../context/auth/auth.provider";
import { useProject } from "../../context/project/project.provider";

export const Init = () => {
  const { organisationActive, ready } = useInit();
  const { search, hash } = useLocation();
  const { isAuthenticated } = useAuth();
  const { project, setProject } = useProject();
  const { getParam } = useQueryParams();

  const projectId = getParam("project");
  const redirectWithProj = getParam("redirect");
  const isOrgLoaded = organisationActive !== "loading";

  if (!ready && !isOrgLoaded) return <Loading />;

  if (projectId && !project?.id && projectId !== "undefined") {
    setProject(projectId);
    return <Loading />;
  }

  if (isAuthenticated && organisationActive && project?.id && redirectWithProj)
    return (
      <Redirect
        to={`${redirectWithProj}?project=${project.id}`}
        data-test="redirect-projects"
      />
    );

  const redirect = search
    ? `${routes.PROJECTS.path}${search}${hash}`
    : routes.PROJECTS.path;

  if (isAuthenticated && organisationActive && redirect)
    return <Redirect to={redirect} data-test="redirect" />;
  if (isAuthenticated && organisationActive && !redirect)
    return <Redirect to={routes.PROJECTS.path} data-test="redirect-projects" />;
  if (isAuthenticated && !organisationActive)
    return <Redirect to={routes.PROFILE.path} data-test="redirect-profile" />;

  return null;
};
